<template>
    <base-content>
        <template #button> </template>
        <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }">
            <a-form-item label="储存区">
                <a-tree-select v-if="use_same_storage" v-model:value="common_storage_id" :treeData="storage_tree" :replaceFields="{ children: 'children', title: 'name', key: 'id', value: 'id' }" treeDefaultExpandAll />
                <a-checkbox v-model:checked="use_same_storage">入库时使用相同的储存区</a-checkbox>
            </a-form-item>
            <a-form-item label="供应商">
                <a-select v-if="use_same_supplier" v-model:value="common_supplier_id" show-search :filterOption="filter">
                    <a-select-option :value="supplier.supplier_id" v-for="supplier in supplier_list" :key="supplier.supplier_id" :label="supplier.name">{{ supplier.name }}</a-select-option>
                </a-select>
                <a-checkbox v-model:checked="use_same_supplier">入库时使用相同的供应商</a-checkbox>
            </a-form-item>
            <a-form-item label="批号">
                <a-input v-show="use_same_batch_number" v-model:value="common_batch_number"></a-input>
                <a-checkbox v-model:checked="use_same_batch_number">入库时使用相同的批号</a-checkbox>
                <a-checkbox v-model:checked="auto_batch_number" style="margin-left:12px">添加物料时自动生成批号</a-checkbox>
            </a-form-item>
            <a-form-item label="物料详情">
                <a-button @click="material_list.push({ material_id: '', material_code: '', batch_number: '', name: '', count: '', unit: '', supplier_id: '', storage_id: '' })">新增空行</a-button>
                <a-button @click="$refs.SelectTotalMaterial.start()" style="margin-left:12px">从物料库中添加</a-button>
                <a-table style="margin-top:6px" :columns="table_columns" bordered size="small" :data-source="material_list" rowKey="material_id" :pagination="false">
                    <template #material_code="scoped">
                        <a-input size="small" v-model:value="scoped.record.material_code" :maxlength="7" @change="inputMaterialCode" :index="scoped.index"></a-input>
                    </template>
                    <template #batch_number="scoped">
                        <a-input size="small" v-model:value="scoped.record.batch_number"></a-input>
                    </template>
                    <template #supplier="scoped">
                        <a-select size="small" v-model:value="scoped.record.supplier_id" show-search :filterOption="filter">
                            <a-select-option :value="supplier.supplier_id" v-for="supplier in supplier_list" :key="supplier.supplier_id" :label="supplier.name">{{ supplier.name }}</a-select-option>
                        </a-select>
                    </template>
                    <template #storage="scoped">
                        <a-tree-select size="small" v-model:value="scoped.record.storage_id" :treeData="storage_tree" :replaceFields="{ children: 'children', title: 'name', key: 'id', value: 'id' }" treeDefaultExpandAll />
                    </template>
                    <template #operation="scoped">
                        <a-button type="link" size="small" @click="material_list.splice(scoped.index, 1)">删除</a-button>
                    </template>
                    <template #count="scoped">
                        <a-input size="small" style="text-align:right" v-model:value="scoped.record.count"></a-input>
                    </template>
                </a-table>
            </a-form-item>
            <a-form-item label="操作备注">
                <a-input v-model:value="remark"></a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 2 }">
                <a-button type="primary" @click="submit">提交</a-button>
            </a-form-item>
        </a-form>
        <a-modal v-model:visible="confirm_modal_visible" title="入库申请确认" :width="888" @ok="confrim">
            <a-table v-if="empty_material_list.length > 0" :columns="empty_table_columns" :data-source="empty_material_list" bordered size="small" rowKey="index" :pagination="false">
                <template #title>初次入库项</template>
            </a-table>
            <a-table v-if="exist_material_list.length > 0" :columns="exist_table_columns" :data-source="exist_material_list" bordered size="small" rowKey="index" :pagination="false">
                <template #title>补货项</template>
                <template #footer>补货时会更新储存区设置为此次新设定的，请检查实际物理存储位置是否符合预期。</template>
            </a-table>
        </a-modal>
        <SelectTotalMaterial ref="SelectTotalMaterial" dataType="code" :multiple="true" @finish="finish" />
    </base-content>
</template>

<script>
import DataLog from '../components/modal/DataLog';
import SelectTotalMaterial from '../components/modal/SelectTotalMaterial';
export default {
    components: {
        DataLog,
        SelectTotalMaterial,
    },
    data() {
        return {
            // 用于树选择的选项
            storage_tree: [],
            storage_list: [],
            // 用于选择供应商的选项
            supplier_list: [],
            // 表格中的数据
            material_list: [],
            // 入库操作的说明
            remark: '',
            use_same_supplier: false,
            common_supplier_id: '',
            use_same_batch_number: false,
            common_batch_number: '',
            use_same_storage: false,
            common_storage_id: '',
            auto_batch_number: true,
            // 仓库中已存在的物料列表
            exist_material_list: [],
            // 仓库中不存在的物料列表
            empty_material_list: [],
            // 用于确认的弹窗
            confirm_modal_visible: false,
            empty_table_columns: [
                { title: '物料编码', dataIndex: 'material_code' },
                { title: '名称', dataIndex: 'name' },
                { title: '批号', dataIndex: 'batch_number' },
                { title: '供应商', dataIndex: 'supplier_name' },
                { title: '储存区', dataIndex: 'new_storage_name' },
                { title: '数量', dataIndex: 'change_count' },
            ],
            exist_table_columns: [
                { title: '物料编码', dataIndex: 'material_code' },
                { title: '名称', dataIndex: 'name' },
                { title: '批号', dataIndex: 'batch_number' },
                { title: '供应商', dataIndex: 'supplier_name' },
                { title: '原储存区', dataIndex: 'old_storage_name' },
                { title: '新储存区', dataIndex: 'new_storage_name' },
                { title: '原库存', dataIndex: 'inventory' },
                { title: '数量', dataIndex: 'change_count' },
            ],
            // 临时记录预入库物料列表，用于确认后进行提交
            alreday_in_storage_material_list: [],
        };
    },
    computed: {
        table_columns() {
            let temp = [
                { title: '编码', slots: { customRender: 'material_code' }, width: 100 },
                { title: '名称', dataIndex: 'name' },
                { title: '数量', slots: { customRender: 'count' }, width: 60, align: 'right' },
                { title: '单位', dataIndex: 'unit', width: 50 },
                { title: '操作', align: 'center', width: 55, slots: { customRender: 'operation' } },
            ];
            let temp1 = { title: '批号', slots: { customRender: 'batch_number' }, width: 150 };
            let temp2 = { title: '供应商', slots: { customRender: 'supplier' }, width: 140 };
            let temp3 = { title: '储存区', slots: { customRender: 'storage' }, width: 160 };
            if (!this.use_same_batch_number) temp.splice(1, 0, temp1);
            if (!this.use_same_supplier) temp.splice(-3, 0, temp2);
            if (!this.use_same_storage) temp.splice(-3, 0, temp3);
            return temp;
        },
    },
    watch: {
        use_same_batch_number(value) {
            if (value && this.auto_batch_number) this.common_batch_number = this.createBatchNumber();
        },
    },
    mounted() {
        this.$api('getStorageTree').then((res) => {
            this.storage_tree = res.storage_tree;
            if (this.storage_tree[0].id == 0) this.storage_tree[0].disabled = true;
        });
        this.$api('getTotalSupplierList').then((res) => {
            this.supplier_list = res.supplier_list;
        });
        this.$api('getStorageList', { parent_id: -1 }).then((res) => {
            this.storage_list = res.storage_list;
        });
    },
    methods: {
        _10to32(num10) {
            num10 = parseInt(num10);
            if (num10 < 0 || num10 > 1048575) {
                throw new Error('用于转换的10进制数太小或太大，仅限0~1048575，而你传入了：' + num10);
            }
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            let str32 = '';
            while (true) {
                str32 = map[num10 % 32] + str32;
                num10 = parseInt(num10 / 32);
                if (num10 == 0) {
                    return ('000' + str32).slice(-4);
                }
            }
        },
        _32to10(str32) {
            str32 = String(str32);
            if (str32.length > 4 || str32.length < 1) {
                throw new Error('用于转换的32进制数太小或太大，仅限0000~YYYY，而你传入了：' + str32);
            }
            let num10 = 0;
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            for (let i = 0; i < str32.length; i++) {
                let index = map.indexOf(str32[i]);
                if (index == -1) {
                    throw new Error('用于转换的32进制数字符不符，仅限0123456789ABCEFGHJKLMNPQRSTUVWXY，而你传入了：' + str32[i]);
                }
                num10 += index * 32 ** (str32.length - 1 - i);
            }
            return num10;
        },
        async inputMaterialCode(event) {
            let material_code = event.target.value;
            if (material_code.length == 7) {
                material_code = material_code.toUpperCase();
                material_code = material_code.split('-');
                if (material_code.length != 2 || material_code[0].length != 2 || material_code[1].length != 4) {
                    this.$message.error('物料编码格式不正确，应为XX-XXXX');
                    return;
                }
                let [prefix, suffix] = [material_code[0], material_code[1]];
                let f = (v) => {
                    return '0123456789ABCEFGHJKLMNPQRSTUVWXY'.indexOf(v) != -1;
                };
                if (!prefix.split('').every(f) || !suffix.split('').every(f)) {
                    this.$message.error('物料编码格式不正确，使用了不合法的字符');
                    return;
                }
                suffix = this._32to10(suffix);
                let res = await this.$api('getMaterialBrief', { prefix, suffix });
                let index = event.target.getAttribute('index');
                this.material_list[index].name = res.result ? res.material_brief.name : '';
                this.material_list[index].unit = res.result ? res.material_brief.unit : '';
                this.material_list[index].material_id = res.result ? res.material_brief.material_id : '';
                this.material_list[index].material_code = res.result ? material_code.join('-') : '';
                if (!res.result) {
                    this.$message.error('不存在该物料，请核对后再试');
                    return;
                }
                if (this.auto_batch_number) {
                    this.material_list[index].batch_number = this.createBatchNumber();
                }
            }
        },
        finish(code_list) {
            code_list.forEach(async (item) => {
                item = item.split('-');
                let res = await this.$api('getMaterialBrief', { prefix: item[0], suffix: this._32to10(item[1]) });
                let batch_number = this.auto_batch_number ? this.createBatchNumber() : '';
                let material = {
                    material_id: res.material_brief.material_id,
                    material_code: item.join('-'),
                    batch_number,
                    name: res.material_brief.name,
                    count: '',
                    unit: res.material_brief.unit,
                    storage_id: '',
                    supplier_id: '',
                };
                this.material_list.push(material);
            });
        },
        createBatchNumber() {
            let date = new Date();
            let y = String(date.getFullYear());
            let m = ('00' + String(date.getMonth() + 1)).slice(-2);
            let d = ('00' + String(date.getDate())).slice(-2);
            let h = ('00' + String(date.getHours())).slice(-2);
            let i = ('00' + String(date.getMinutes())).slice(-2);
            let s = ('00' + String(date.getSeconds())).slice(-2);
            // let t = ('000' + String(date.getTime() % 1000)).slice(-3);
            return y + m + d + h + i + s;
            // return y + m + d + h + i + s + t;
        },
        submit() {
            let flag = false;
            if (!flag && this.use_same_storage && !this.common_storage_id) flag = '请选择储存区';
            if (!flag && this.use_same_supplier && !this.common_supplier_id) flag = '请选择供应商';
            if (!flag && this.use_same_batch_number && !this.common_batch_number) flag = '请输入批号';
            let valid_material_list = [];
            if (!flag)
                for (let i = 0; i < this.material_list.length; i++) {
                    if (!flag && this.material_list[i].material_code && !this.material_list[i].material_id) flag = `列表的第${i + 1}行不是有效物料`;
                    if (!flag && this.material_list[i].material_id && !this.use_same_batch_number && this.material_list[i].batch_number.replace(' ', '') === '') flag = `列表的第${i + 1}行未输入批号`;
                    if (!flag && this.material_list[i].material_id && !this.use_same_supplier && !this.material_list[i].supplier_id) flag = `列表的第${i + 1}行未选择供应商`;
                    if (!flag && this.material_list[i].material_id && !this.use_same_storage && !this.material_list[i].storage_id) flag = `列表的第${i + 1}行未选择储存区`;
                    if (!flag && this.material_list[i].material_id && this.material_list[i].count === '') flag = `列表的第${i + 1}行未输入数量`;
                    if (!flag && this.material_list[i].material_id && isNaN(Number(this.material_list[i].count))) flag = `列表的第${i + 1}行数量输入有误`;
                    if (!flag && this.material_list[i].material_id && Number(this.material_list[i].count) <= 0) flag = `列表的第${i + 1}行数量输入有误`;
                    if (!flag && this.material_list[i].material_id) {
                        this.material_list[i].count = Number(this.material_list[i].count);
                        if (this.use_same_batch_number) this.material_list[i].batch_number = this.common_batch_number;
                        if (this.use_same_supplier) this.material_list[i].supplier_id = this.common_supplier_id;
                        if (this.use_same_storage) this.material_list[i].storage_id = this.common_storage_id;
                        valid_material_list.push(this.material_list[i]);
                    }
                    if (flag) break;
                }
            if (!flag && valid_material_list.length == 0) flag = '请至少添加一项物料';
            let material_key_list = [];
            if (!flag)
                for (let i = 0; i < valid_material_list.length; i++) {
                    let material_key = `${valid_material_list[i].material_id}_${valid_material_list[i].supplier_id}_${valid_material_list[i].batch_number}`;
                    if (material_key_list.indexOf(material_key) != -1) flag = `列表中第${material_key_list.indexOf(material_key) + 1}行与第${i + 1}行重复`;
                    if (!flag) material_key_list.push(material_key);
                    if (flag) break;
                }
            if (flag) this.$message.error(flag);
            if (flag) return;
            /**
             * 在此之前都是对数据进行校验，若不满足条件则会弹出提醒，并终止程序运行
             * 校验完成后满足条件的物料列表是valid_material_list，将其进行提交即可
             */
            this.$api('preInStorage', { material_list: JSON.stringify(valid_material_list) }).then((res) => {
                this.empty_material_list = [];
                this.exist_material_list = [];
                // 循环返回结果中的每一项，生成用于预览的表格，分首次入库和补货两种
                res.result_list.forEach((item1) => {
                    // 从material_key中分离三个关键参
                    let [material_id, supplier_id, batch_number] = item1.material_key.split('_');
                    // batch_number 批号
                    item1.batch_number = batch_number;
                    this.supplier_list.forEach((item2) => {
                        // supplier_name 供应商的名称
                        if (supplier_id == item2.supplier_id) item1.supplier_name = item2.name;
                    });
                    valid_material_list.forEach((item2) => {
                        if (item2.material_id == material_id && item2.supplier_id == supplier_id && item2.batch_number == batch_number) {
                            // name 物料名称
                            item1.name = item2.name;
                            // material_code 物料编码
                            item1.material_code = item2.material_code;
                            // new_storage_id 新储存区id
                            item1.new_storage_id = item2.storage_id;
                            // old_storage_id 老储存区id（如果是初次入库，这个字段是不显示的，因此置为空值也无所谓，只是为了下面查找储存区名称的循环时可以方便一点）
                            item1.old_storage_id = item1.exist ? item1.storage_id : '0';
                            // count 变化的库存数量
                            item1.change_count = item2.count;
                        }
                    });
                    this.storage_list.forEach((item2) => {
                        if (item1.new_storage_id == item2.id) item1.new_storage_name = item2.name;
                        if (item1.old_storage_id == item2.id) item1.old_storage_name = item2.name;
                    });
                    // inventory 变化前的库存数量
                    item1.inventory = item1.exist ? item1.count : 0;
                    // 用在v-for循环中绑定的唯一key
                    item1.index = res.result_list.indexOf(item1);
                    // 向exist或empty数组添加数据，用以展示
                    this[`${item1.exist ? 'exist' : 'empty'}_material_list`].push(item1);
                });
                this.alreday_in_storage_material_list = valid_material_list;
                this.confirm_modal_visible = true;
            });
        },
        confrim() {
            this.$api('applyInStorage', { material_list: JSON.stringify(this.alreday_in_storage_material_list), remark: this.remark }).then((res) => {
                if (res.result) {
                    this.material_list = [];
                    this.use_same_supplier = false;
                    this.use_same_batch_number = false;
                    this.use_same_storage = false;
                    this.common_supplier_id = '';
                    this.common_batch_number = '';
                    this.common_storage_id = '';
                    this.confirm_modal_visible = false;
                    this.remark = '';
                    this.$modal.success({
                        title: '提交完成',
                        content: '入库申请已提交，审核通过后才会变更库存数量，本次入库流水号：' + res.serial + '。',
                    });
                }
            });
        },
        /**
         * 选项筛选
         */
        filter(input, option) {
            let p1 = this.$pinyin.getCamelChars(option.label).toLowerCase();
            let p2 = this.$pinyin.getFullChars(option.label).toLowerCase();
            let t1 = p1.indexOf(input) != -1;
            let t2 = p2.indexOf(input) != -1;
            let t3 = option.label.indexOf(input) != -1;
            let t4 = String(option.key).indexOf(input) != -1;
            return t1 || t2 || t3 || t4;
        },
    },
};
</script>

<style></style>
